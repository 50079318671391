/* @import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Comfortaa');
body{
    font-family: Roboto!important;
    width:100%;
    padding: 0;
    overflow: hidden;
} */
#root {
    height: 100%;
}

.f-12 {
    font-size: 12px
}

.f-16 {
    font-size: 16px
}

.f-20 {
    font-size: 20px
}

.mr-5 {
    margin-right: 5px;
}

.mr-10 {
    margin-right: 10px;
}

.mr-15 {
    margin-right: 15px;
}

.mr-20 {
    margin-right: 20px;
}

.ml-5 {
    margin-left: 5px;
}

.ml-10 {
    margin-left: 10px;
}

.ml-15 {
    margin-left: 15px;
}

.ml-20 {
    margin-left: 20px;
}

.mt-5 {
    margin-top: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.mb-5 {
    margin-bottom: 5px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-20 {
    margin-bottom: 20px;
}

.pl-5 {
    padding-left: 5px;
}

.pl-10 {
    padding-left: 10px;
}

.pl-15 {
    padding-left: 15px;
}

.pl-20 {
    padding-left: 20px;
}

.pr-5 {
    padding-right: 5px;
}

.pr-10 {
    padding-right: 10px;
}

.pr-15 {
    padding-right: 15px;
}

.pr-20 {
    padding-right: 20px;
}

.pl-5 {
    padding-left: 5px;
}

.pl-10 {
    padding-left: 10px;
}

.pl-15 {
    padding-left: 15px;
}

.pl-20 {
    padding-left: 20px;
}

.pt-5 {
    padding-top: 5px;
}

.pt-10 {
    padding-top: 10px;
}

.pt-15 {
    padding-top: 15px;
}

.pt-20 {
    padding-top: 20px;
}

.pb-5 {
    padding-bottom: 5px;
}

.pb-10 {
    padding-bottom: 10px;
}

.pb-15 {
    padding-bottom: 15px;
}

.pb-20 {
    padding-bottom: 20px;
}

.center {
    text-align: center;
}

.ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.4);
}

.app-content-block {
    overflow: auto;
    background-color: #F8FAFB;
    padding: 40px;
}

.app-sidebar-menu>.ant-menu-item,
.page-sidebar-menu>.ant-menu-item {
    height: 48px;
    line-height: 48px;
}

.app-sidebar-menu,
.page-sidebar-menu {
    background-color: #F8FAFB;
    border: 0;
    padding-top: 20px;
}

.app-sidebar-menu>.ant-menu-item:active,
.app-sidebar-menu>.ant-menu-submenu-title:active,
.page-sidebar-menu>.ant-menu-item:active,
.page-sidebar-menu>.ant-menu-submenu-title:active {
    background: #fff;
}

.app-sidebar-menu .ant-menu-item-selected,
.page-sidebar-menu>.ant-menu-item-selected {
    background-color: #fff !important;
    border-radius: 4px;
}

.app-sidebar-menu .ant-menu-item-selected>a {
    color: #44566C !important;
}

.app-sidebar-menu .ant-menu-item::after,
.page-sidebar-menu>.ant-menu-item::after {
    border-color: transparent
}

.app-sidebar-menu>.ant-menu-item {
    height: 45px;
    line-height: 45px;
}

.app-sidebar-menu>.custom-list {
    padding: 20px 24px;
    font-size: 12px;
    text-transform: uppercase;
    color: #8697A8;
}

/* .app-sidebar-menu .ant-menu-item>a,
.app-sidebar-menu>.ant-menu-submenu>.ant-menu-submenu-title {
    text-shadow: 0px 0px;
    color: #7C8898;
    font-weight: 500;
} */

.ant-menu-submenu>.ant-menu {
    background: transparent;
}

.page-sidebar-menu>.ant-menu-item .ant-badge-status-dot {
    width: 12px;
    height: 12px;
}

.page-sidebar-menu>.ant-menu-item .ant-badge-status-text {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #44566C;
    margin-left: 12px;
}

.b {
    font-weight: bold;
}

.fw {
    width: 100%
}

.fh {
    height: 100%
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.select-box>.ant-select-selection {
    border: 0;
    margin-left: 15px;
    font-size: 15px;
    box-shadow: 0px 8px 16px rgba(169, 194, 209, 0.1);
}

.input-box>input {
    border: 0;
    font-size: 15px;
    box-shadow: 0px 8px 16px rgba(169, 194, 209, 0.1);
}

.searchbox input {
    padding-left: 40px !important;
}

/*Scrollbar CSS*/
::-webkit-scrollbar {
    width: 8px;
    height: 7px;
    background-color: rgba(0, 0, 0, 0);
    -webkit-border-radius: 100px;
    border-radius: 100px;
}

::-webkit-scrollbar:hover {
    background-color: rgba(0, 0, 0, 0.09);
}

::-webkit-scrollbar-thumb:vertical {
    background: rgba(0, 0, 0, 0.5);
    -webkit-border-radius: 100px;
    border-radius: 100px;
    background-clip: padding-box;
    border: 2px solid rgba(0, 0, 0, 0);
    min-height: 10px;
    /*Prevent it from getting too small */
}

::-webkit-scrollbar-thumb:vertical:active {
    background: rgba(0, 0, 0, 0.61);
    /* Some darker color when you click it */
    -webkit-border-radius: 100px;
    border-radius: 100px;
}

::-webkit-scrollbar-thumb:horizontal {
    background: rgba(0, 0, 0, 0.5);
    -webkit-border-radius: 100px;
    border-radius: 100px;
    background-clip: padding-box;
    border: 2px solid rgba(0, 0, 0, 0);
    min-height: 10px;
    /*Prevent it from getting too small */
}

::-webkit-scrollbar-thumb:horizontal:active {
    background: rgba(0, 0, 0, 0.61);
    /* Some darker color when you click it */
    -webkit-border-radius: 100px;
    border-radius: 100px;
}

/*------------END-----------*/

.f15 {
    font-size: 15px !important;
}

.fieldBkgd input,
.fieldBkgd {
    background: #F8FAFB !important;
    color: rgba(0, 0, 0, 0.65) !important;
}

.LoginUserInput,
.LoginUserInput input {
    border: 0;
    padding-left: 28px !important;
    box-shadow: none !important;
    font-size: 15px !important;
}

/* rohit css data: forgot password */

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, .4);
    animation: all .3s ease-in-out forwards;
}

.custom-popup {
    height: 445px;
    width: 430px;
    box-shadow: 0 8px 16px rgba(169, 194, 209, 0.1);
    z-index: 100;
    border-radius: 8px;
    overflow: hidden;
}

/* style for dots in forgot/reset password */
.dot {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 12px;
}

.text-font-fp {
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
    color: #44566C;
    font-weight: bold;
}

/* for padding in Input boxes And No border*/

.userInput input {
    border: 0;
    padding-left: 41px !important;
}

/* font of link items */

.link-font {
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: #0081FF;
}

/* reset password css:rohit */

.text-font ::placeholder {
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
}

/*  signup details text style */
.text-color {
    font-weight: 300;
    line-height: 16px;
    color: #44566C;

}

.shadow {
    box-shadow: 0 8px 16px rgba(169, 194, 209, 0.1);
}

.fontColor div div {
    color: #44566C;
}

.custom-modal label {
    color: #8697A8;
    font-size: 15px
}

.custom-modal>.ant-modal-content {
    padding: 40px;
}

.custom-modal>.ant-modal-content>.ant-modal-header,
.custom-modal>.ant-modal-content>.ant-modal-footer {
    padding: 0;
    border: 0;
    /* text-align: center; */
}

.custom-modal>.ant-modal-content>.ant-modal-header>.ant-modal-title {
    font-size: 30px;
    line-height: 35px;
    color: #44566C;
    font-weight: bold;
}

.custom-modal>.ant-modal-content>.ant-modal-close>.ant-modal-close-x {
    background: #EAEDF0;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    line-height: 31px;
    font-size: 14px;
    color: #44566C;
}

.custom-modal>.ant-modal-content>.ant-modal-close {
    top: 40px;
    right: 40px;
}

.custom-modal>.ant-modal-content>.ant-modal-footer>button {
    min-width: 110px;
    height: 40px;
    font-size: 15px;
}

.custom-modal>.ant-modal-content>.ant-modal-body {
    padding: 30px 0;
}

.custom-modal>.ant-modal-content {
    border-radius: 10px
}

.custom-input>input[type='text'],
.custom-input .ant-input,
textarea.custom-input,
.custom-input input,
input.custom-input {
    background: #F8FAFB;
    border-color: transparent;
    font-size: 15px;
    padding: 6px 16px;
    box-shadow: none;
}

.custom-input.white>input[type='text'],
.custom-select.white>.ant-select-selection {
    background: #fff !important
}

textarea.custom-input:focus {
    box-shadow: none;
}

.custom-input input:focus {
    border-color: #40a9ff
}

.custom-input .ant-input-group-addon {
    border: 0;
    background: #D8DADB;
}

.custom-input.ant-input-number {
    border-color: transparent;
}

.custom-input .ant-input-number-handler-wrap {
    background: #F8FAFB;
}

.custom-input .ant-input-number-input {
    height: 40px;
}

.custom-select>.ant-select-selection {
    background: #F8FAFB;
    border-color: transparent;
    border-radius: 5px;
    font-size: 14px;
    box-shadow: none;
}

.custom-select .ant-select-selection__placeholder {
    color: #8697A8;
    font-size: 15px
}

.custom-select .ant-select-selection__choice {
    background: #EAEDF0;
    border-radius: 5px;
    color: #44566C;
}

/*------------------------ Grid Layout -----------------------------*/
.react-grid-layout {
    position: relative;
    -webkit-transition: height 200ms ease;
    transition: height 200ms ease;
}

.react-grid-item {
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    -webkit-transition-property: left, top;
    transition-property: left, top;
}

.react-grid-item.cssTransforms {
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
}

.react-grid-item.resizing {
    z-index: 1;
    will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
    -webkit-transition: none;
    transition: none;
    z-index: 3;
    will-change: transform;
}

.react-grid-item.react-grid-placeholder {
    background: turquoise;
    opacity: 0.2;
    -webkit-transition-duration: 100ms;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.react-grid-item>.react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    cursor: se-resize;
}

.react-grid-item>.react-resizable-handle::after {
    content: "";
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 5px;
    height: 5px;
    border-right: 2px solid rgba(0, 0, 0, 0.4);
    border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
    background-position: bottom right;
    padding: 0 3px 3px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: se-resize;
}

.react-draggable-transparent-selection *::-moz-selection {
    background: transparent;
}

.react-draggable-transparent-selection *::selection {
    background: transparent;
}

.gridDragHandler>.recharts-responsive-container {
    background: #fff;
    border-radius: 5px
}

.gridDragHandler.active {
    background: white;
    z-index: 101;
}

.gridDragHandler:hover {
    border-color: grey;
}

.gridDragHandler {
    /* padding:8px; */
    padding: 2px 5px;
    border-radius: 4px;
    border: 1px dashed transparent;
    /* text-align: center; */
    color: #8697A8;
    cursor: move;
    /* min-width: 400px;
    min-height: 400px; */
}

.gridDragHandler>.editChart {
    opacity: 0;
    position: absolute;
    right: 15px;
    background: white;
    top: 15px;
    z-index: 100;
    border-radius: 5px;
}

.gridDragHandler>.editChart>.anticon {
    color: rgb(135, 152, 168);
    font-size: 18px;
    margin-left: 10px;
    cursor: pointer;
}

.gridDragHandler>.report-name {
    padding: 10px;
    background-color: #fff;
    font-size: 18px;
    font-weight: normal;
}

.gridDragHandler:hover .editChart {
    opacity: 1
}

.react-resizable {
    position: relative;
    overflow: hidden;
}

.ant-select-selection:hover,
.custom-select .ant-select-selection:focus {
    border-color: #40a9ff !important;
}

.fieldListColumn {
    height: calc(100vh - 210px);
    overflow: auto;
}

.fieldListColumn>.items {
    margin-bottom: 20px;
    height: 40px;
    background: white;
    line-height: 40px;
    border-radius: 5px;
    box-shadow: 0px 8px 16px rgba(169, 194, 209, 0.1);
    cursor: grab;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fieldListColumn>.items>.anticon {
    font-size: 20px;
    margin: 0px 20px;
    vertical-align: middle;
}

.overlayWithHeader {
    position: absolute;
    top: 64px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    overflow: hidden;
    /* height:100%; */
}

.editableInput {
    padding: 0;
    border: 0;
    box-shadow: none !important;
    border-radius: 0;
    width: 400px;
}

.editableInput:hover {
    border-bottom: 1px dashed lightgrey;
}

.gridDragHandler>.edit {
    position: absolute;
    top: 1px;
    right: 5px;
    opacity: 0;
}

.gridDragHandler>.edit>.anticon,
.pointer {
    cursor: pointer;
}

.gridDragHandler:hover .edit {
    opacity: 1
}

.arrow-up {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 15px solid #F1EFEF;
    top: -15px;
}

.propertyTabs .ant-tabs-tab:hover {
    color: #44566C;
}

.propertyTabs .ant-tabs-tab {
    padding: 15px 24px !important;
    color: #44566C;
    text-align: center !important;
}

.propertyTabs .ant-tabs-tab-active.ant-tabs-tab {
    background: #F1EFEF;
    font-weight: bold;
}

.propertyTabs .ant-tabs-bar.ant-tabs-left-bar {
    border-right: 0;
    background: #F6F6F6;
    width: 125px;
}

.propertyTabs .ant-tabs-ink-bar {
    background-color: transparent
}

.propertyTabs .ant-tabs-left-content {
    border-left: 0;
    padding: 15px;
}

.divider {
    color: #0081FF !important;
    font-size: 12px !important;
    cursor: pointer;
}

.ant-select-dropdown-menu-item-group-title {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    font-weight: bold;
    background: #EAEDF0;
    color: #44566C;
}

.ant-select-dropdown-menu-item-group-list>.ant-select-dropdown-menu-item {
    padding-left: 30px;
}

.wfColumn {
    display: inline-block;
    width: 300px;
    background: #FFF;
    min-height: calc(100vh - 200px);
    border-radius: 5px;
    margin-left: 30px;
    padding: 20px 15px;
}

.gradient-bg {
    background: linear-gradient(to top right, #ff5263 0%, #ff7381 35%, #fcbd01 100%);
    border-style: none;
}

.gradient-bg:hover,
.gradient-bg:focus,
.gradient-bg:active {
    background: linear-gradient(to top right, #ff1f35, #ff4053 35%, #ff6378);
    box-shadow: none;
}

.fieldItem {
    padding: 10px;
    background: white;
    width: 240px;
    height: 40px;
    border-radius: 5px;
    display: inline-block;
    margin-right: 50px;
    margin-bottom: 15px;
    box-shadow: 0px 8px 16px rgba(169, 194, 209, 0.1);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fieldItem i {
    color: rgb(68, 86, 108);
    font-size: 20px;
    margin-right: 10px;
}

.fieldItem .edit {
    opacity: 0;
}

.fieldItem:hover .edit {
    opacity: 1;
}

.fieldItem .edit>i {
    font-size: 14px;
    margin-right: 10px;
    color: rgb(179, 192, 206);
}

.fieldItem .edit>i:hover {
    color: #44566C;
}

.custom-tabs .ant-tabs-nav .ant-tabs-tab {
    font-size: 16px;
    padding-left: 2px;
}

.custom-tabs .ant-tabs-nav .ant-tabs-tab-active,
.custom-tabs .ant-tabs-nav .ant-tabs-tab:hover {
    color: #56667A;
    text-shadow: 0 0;
}

.custom-tabs .ant-tabs-tabpane {
    overflow-y: auto;
    overflow-x: hidden;
    /* height: calc(100vh - 148px); */
    height: calc(100vh - 111px);
}

.custom-switch {
    font-size: 12px;
    background: rgb(248, 250, 251);
    padding: 4px;
    display: inline-flex;
    border-radius: 6px;
}

.custom-switch>div {
    color: #44566C;
    padding: 6px;
    border-radius: 4px;
    margin-right: 5px;
    cursor: pointer;
}

.custom-switch>div.active {
    background: #09B66D;
    color: #fff;
}

.wfnode {
    width: 250px;
    height: 90px;
    background: rgb(255, 255, 255);
    padding: 15px;
    box-shadow: 0px 8px 16px rgba(169, 194, 209, 0.1);
    border-radius: 4px;
    position: absolute;
}

.wfnode.ui-state-highlight {
    background: linear-gradient(to right, rgba(255, 132, 132, 0.57), rgb(255, 99, 99));
}

.wfnode>input {
    background: transparent;
}

.wfnode>.delete {
    opacity: 0;
    color: grey;
    position: absolute;
    top: 10px;
    right: 10px;
}

.wfnode>.delete:hover {
    color: #000;
    cursor: pointer;
}

.wfnode:hover>.delete {
    opacity: 1;
}

.wf-gradient {
    background-size: 20px 20px;
    background-image: linear-gradient(to right, rgb(237, 240, 247), transparent 1px), linear-gradient(to bottom, rgb(237, 240, 247), transparent 1px);
}

#connector-canvas {
    width: 100%;
    height: 100%;
    /* stroke: #5c96bc; */
    stroke: #FF5067;
    stroke-width: 2;
    fill: none;
}

.wfnode.initiator>.anchor-points,
.wfnode:hover>.anchor-points {
    opacity: 1;
}

.wfnode>.anchor-points {
    background: #FFF1F0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    top: 90%;
    position: absolute;
    /* left: 45%; */
    color: #FF5067;
    font-size: 16px;
    opacity: 0;
}

polyline:hover {
    cursor: pointer;
    stroke: blue;
}

.custom-card {
    border-radius: 10px;
    width: 200px;
    height: 200px;
    display: inline-flex;
    margin-right: 36px;
    margin-bottom: 36px;
    position: relative;
    cursor: pointer;
    box-shadow: 0px 8px 16px rgba(169, 194, 209, 0.1);
}

.custom-card .ant-card-body {
    width: 100%;
}

.custom-card .delete {
    opacity: 0;
}

.custom-card:hover .delete {
    opacity: 1;
}

.custom-table {
    background: #fff;
    margin: 40px 0;
}

.custom-table .ant-table-thead>tr>th {
    background: #F8FAFB;
    text-shadow: 0 0;
}

.custom-table .ant-table-pagination.ant-pagination {
    position: relative;
    top: 40px;
    margin: -16px 0px;
}

.ant-table-thead>tr>th,
.ant-table-tbody>tr>td {
    padding: 8px 15px !important;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* .triggers .ant-switch{
    background: #FF3D57;
} */
.triggers .ant-switch.ant-switch-checked {
    background: #09B66D;
}

/* .triggers .ant-switch.ant-switch-disabled{
    background: #828282;
} */
/* .triggers .ant-switch::after{
    width:14px;
    height: 14px;
} */
.ant-select-disabled .ant-select-selection {
    background: #f5f5f5
}

.ant-select-disabled .ant-select-selection:hover {
    border-color: #d9d9d9 !important
}

.custom-mention {
    border-color: transparent;
}

.custom-mention>textarea {
    background: #F8FAFB;
    border-color: transparent;
    padding: 15px;
}

.custom-mention.ant-mentions-disabled>textarea {
    /* background: #f5f5f5 !important; */
}

.error-alert {
    box-shadow: 0px 0px 4px red;
}