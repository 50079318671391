.project-task-details-page {
  display: grid;
  grid-template-columns: repeat(2, minmax(240px, 1fr)) 0.6fr;
  grid-template-rows: 0.5fr repeat(2, minmax(200px, 1fr));
  grid-gap: 25px;
  grid-template-areas:
    "head head sidebar"
    "body body sidebar"
    "body body sidebar";

  .header-container {
    grid-area: head;
    background-color: var(--white);
    padding: var(--spacing-m);
    border-radius: 8px;
    .page-heading {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      display: flex;
      align-items: center;
      color: var(--primary);
    }
    .sub-header {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;

      display: flex;
      color: var(--primary-light);
    }
    .ant-avatar.btn-select-freelancer {
      background: var(--primary);
      opacity: 0.6;
    }

    @media (min-width: 1200px) and (max-width: 1300px) {
      .task-summary-container {
        display: block !important;
        flex: 0 0 100%;
        max-width: 100%;
      }

      .action-btn-container {
        display: block;
        flex: 0 0 50%;
        max-width: 50%;

        .ant-col-xl-24 {
          display: block;
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
    }
  }
  .details-continer {
    grid-area: body;
    border-radius: 8px;
    height: 100%;
    background: var(--white);
    position: relative;

    .header {
      border-radius: 8px;
    }
    .body {
      height: calc(100% - 160px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      padding: 25px 30px;
      overflow-y: scroll;
    }
    .footer {
      border-radius: 8px;
      width: 100%;
      background: #ebebeb;
      position: absolute;
      bottom: 0;
      z-index: 1;
      padding: 22px 30px;
      display: flex;
      gap: 30px;
    }
  }
  .sidebar-container {
    grid-area: sidebar;
    border-radius: 8px;
    overflow: hidden;
    object-fit: contain;
    min-width: 300px;
  }
  // global
  .value {
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    white-space: pre-line;
    align-items: center;
    letter-spacing: -0.02em;
    color: var(--primary-light);
  }
  .label {
    color: var(--gray-8);
    font-weight: 500;
  }
}
