.file-item {
  .file-item-container {
    min-width: 209px;
    min-height: 195px;
    border: 1px solid var(--border);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    background: var(--gray-1);
    overflow: hidden;

    .thumbnail {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;

      .file-name {
        width: 100%;
        max-width: 180px;
        margin: 0 auto;
        font-size: 0.875rem;
        color: var(--font-gray);
        font-weight: 500;
        -webkit-box-orient: vertical;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .hover-options-container {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      transition: all ease 0.3s;
      position: absolute;
      opacity: 0;

      .hover-options-header {
        background-color: var(--white);
        padding: var(--spacing-xs);
        cursor: pointer;
      }
    }
  }
}

.file-item:hover {
  .hover-options-container {
    opacity: 1;
  }
}
