.select-freelancer-modal-container {
  .popular-tags {
    display: flex;
    flex-wrap: wrap;
    .tag {
      width: fit-content;
      height: 38px;
      background: #f7f9fc;
      color: #000000;
      border-radius: 8px;
      margin: 0 20px 15px 0;
      padding: 10px;
      cursor: pointer;
      transition: all ease 0.3s;
      p {
        font-size: 16px;
        line-height: 140%;
      }
      &:hover {
        background: #f6d1ff;
      }
      &.active {
        background-color: #f6d1ff;
      }
    }
  }
}
