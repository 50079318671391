.project-draft-container {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;

  .page-body {
    .content {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
      grid-gap: var(--spacing-xl) var(--spacing-s);
      cursor: pointer;
    }
  }
}
