@import url(https://fonts.googleapis.com/css?family=Anonymous+Pro);

.open-ai {

  .social-media-platform-card,
  .counter-card,
  .template-card,
  .task-item-card {
    border-radius: var(--rounded-lg);
  }

  .task-item-card {
    .ant-card-head {
      padding: 0 var(--spacing-m);
    }

    .ant-card-head-title {
      padding: var(--spacing-s) 0;
    }

    .ant-card-body {
      padding: var(--spacing-s) var(--spacing-m);
    }
  }

  .counter-card {
    display: flex;
    height: 10rem;
    align-items: center;

    .ant-card-body {
      width: 100%;
      padding: var(--spacing-l);
    }

    .counter-icon {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .social-media-platform-card,
  .template-card {
    .ant-card-body {
      padding-left: var(--spacing-m);
      padding-right: var(--spacing-m);
    }

    .task-icon {
      stroke: var(--primary-open-ai);
    }

    .ant-card-meta-title {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5rem;
      color: var(--primary);
    }

    .ant-card-meta-description {
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.25rem;

      color: var(--primary);

      opacity: 0.6;
    }

    .ant-checkbox .ant-checkbox-inner {
      background-color: var(--transparent) !important;
    }
  }

  .active-card,
  .social-media-platform-card:hover,
  .template-card:hover {
    background: var(--primary-open-ai);

    .social-media-logo,
    .task-icon {
      filter: brightness(0) invert(1);
      stroke: var(--white);
    }

    .ant-card-meta-title {
      color: var(--white);
    }

    .ant-card-meta-description {
      color: var(--white);
    }

    .ant-checkbox .ant-checkbox-inner {
      border-color: var(--white) !important;
    }
  }

  .recent-content-table-container {
    .table-container {
      width: auto;
      max-height: 36rem;
    }
  }
}

.open-ai-chat {
  .--custom-card-container {
    height: 100%;

    .ant-card-body {
      height: 100%;
    }

    .content-container {
      /*  height: calc(80vh); */
      display: flex;
      flex-direction: column;

      .chat-container {
        flex: 0.9;

        .message-list-container {
          padding-bottom: calc(var(--spacing-xxl) * 3);
          overflow-y: auto;
        }
      }
    }
  }

  .chat-input-container {
    position: absolute;
    /* left: calc(-1 * var(--spacing-xxl-3));
    bottom: calc(-1 * var(--spacing-xxl-3));
    right: calc(-1 * var(--spacing-xxl-3)); */
    /* box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2); */
    left: 0;
    bottom: 0px;
    right: 0;
    border: none;
    border-top: var(--border-1) solid var(--primary-opacity-10);
    /* border-bottom: var(--border-1) solid var(--primary-opacity-20); */

    .chat-input {
      border-radius: var(--rounded);
      min-height: 60px !important;
      background: var(--primary);
      color: #ffffff;
      border: 0px;
      box-shadow: none;

      &:hover {
        border-color: rgba(0, 0, 0, 0.85);
      }
    }

    .ant-card-body {
      /* padding-top: var(--spacing-xl) !important;
      padding-bottom: var(--spacing-xl) !important; */
      padding-top: 10px !important;
      padding-bottom: 10px !important;
      /* background: #F4F4F4; */
      background: var(--primary);
      border: 0px;
      padding-left: 30px !important;
      padding-right: 30px !important;
    }

    .ant-row-bottom {
      align-items: center;
    }

    .ant-btn-icon-only {
      color: #ffffff;

      * {
        font-size: 20px;
      }

      &:hover {
        color: #ffffff;
      }
    }
  }

  .open-ai-chat-item {
    padding-left: 50px;
    padding-right: 20px;

    .chat-item-container {
      flex-wrap: nowrap;
      padding: var(--spacing-xs);
      margin-bottom: var(--spacing-xs);

      .chat-details-container {
        width: 100%;
        margin-left: var(--spacing-s);

        .user-details {
          .name {
            /* font-size: var(--text-base); */
            font-size: 1.071rem;
            font-weight: 600;
            text-transform: capitalize;
            color: var(--primary);
          }
        }

        .chat-details {
          .message {
            /*  font-size: var(--text-base); */
            font-size: 1.071rem;
            font-weight: 500;
            color: var(--primary-light);
            white-space: pre-line;
          }
        }
      }
    }
  }
}


@media (min-width: 1600px) and (max-width: 2000px) {
  .open-ai {

    .social-media-platforms-container .social-media-platform-card-container,
    .tasks-container .task-card-container,
    .templates-container .template-card-container,
    .counters-container .counter-card-container {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
}

.step-card-container {
  .ant-card-head-title {
    white-space: normal;
  }

  .ant-card-body {
    overflow-y: auto;
  }
}

.content-description {
  white-space: pre-line;
}

.animation_p1 {
  //position: relative;
  //color: hsl(0, 0%, 68%);
  //font-weight: bold !important;
  //font-family: "Anonymous Pro", monospace !important;
  //letter-spacing: 7px !important;
  overflow: hidden;
  word-wrap: break-word;
  font-size: 14px;
  //border-right: 2px solid hsl(0, 0%, 80%);
  white-space: nowrap;
  animation: typewriter 2s steps(44, end) 1s 1 normal both,
    blinkTextCursor 100ms infinite;
}

@keyframes typewriter {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes blinkTextCursor {
  from {
    border-right-color: hsl(0, 0%, 80%);
  }

  to {
    border-right-color: transparent;
  }
}

.--custom-card-container {
  border-radius: var(--rounded-lg) !important;

  .ant-card-body {
    padding: var(--spacing-xxl-3) !important;
    border: var(--border-1) solid var(--primary-opacity-10);
    /* padding: 0 !important; */
  }

  .ant-card-head {
    font-size: large;
    color: var(--primary);
    font-weight: 600;

    padding: 0 var(--spacing-xxl-3);
    border-bottom: 0;

    .ant-card-head-wrapper {
      border-bottom: 1px solid var(--primary-opacity-10);
    }
  }

  .title-container {
    border-bottom: var(--border-1) solid var(--primary-opacity-10);
    margin-bottom: var(--spacing-l);
    /*  padding-bottom: var(--spacing-xs); */

    .title {
      font-size: var(--text-2xl);
      color: var(--primary);
      font-weight: 600;
    }
  }
}

.open-ai-generate-content-container {
  .content-container {
    .ant-divider-vertical {
      height: 100%;
    }
  }

  .input-text-editor {

    .ql-toolbar,
    .ql-container {
      border-color: var(--primary-opacity-10);
    }

    .ql-toolbar {
      border-top-left-radius: var(--border-4);
      border-top-right-radius: var(--border-4);
    }

    .ql-container {
      border-bottom-left-radius: var(--border-4);
      border-bottom-right-radius: var(--border-4);
    }
  }
}

.lang-select {
  width: 70vw;
  max-height: 50vh;
  overflow: auto;
}

.generate-blog-container {

  // custom steps response style end
  .steps-container-horizontal {
    display: none;
  }

  .steps-container-vertical {
    display: flex;
  }

  @media (min-width: 1600px) {
    .steps-container-horizontal {
      display: flex;
    }

    .steps-container-vertical {
      display: none;
    }
  }

  // custom steps response style end
}

// openai task details modal container style start
.openai-task-modal-container {
  width: 700px !important;

  .ant-modal-body {
    padding: 0;
  }

  .content-container {
    max-height: 75vh;
    padding: var(--spacing-xl);
    overflow-y: auto;
  }

  .input-field-container {
    margin-bottom: var(--spacing-xs);
  }

  /* Extra small devices (phones, 576px and down) */
  @media only screen and (max-width: 576px) {}

  /* Small devices (portrait tablets and large phones, 576px and up) */
  @media only screen and (min-width: 576px) {}

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    width: 750px !important;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    width: 900px !important;
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {}
}

// openai task details modal container style end



.open-ai-cb {
  &.set-bg {
    background: url(../assets/images/bg.png) no-repeat bottom left;
    background-size: cover;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .--custom-card-container {
    .ant-card-body {
      padding: 0 !important;
    }

    .chat-input-container {
      .ant-card-body {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        background: var(--primary);
        border: 0px;
        padding-left: 30px !important;
        padding-right: 30px !important;
      }
    }
  }
}


.set-container {
  .d-flex-open-ai {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 1.286rem;
    color: var(--primary);
    font-weight: 600;
  }

  .icon-round {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background: #F4F4F4;
    padding: 4px;

    img {
      max-width: 100%;
    }
  }

  .back-icon {
    font-size: 1rem;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}