.comment-box {
  .comment-container {
    z-index: 2;
    width: 100%;
    flex-flow: nowrap;
    padding-right: 5px;

    .input-container {
      width: 100%;
      height: 80%;
      overflow: hidden;
      .custom-mention {
        background: transparent;
        border: 0;
        height: 78px;
        padding: 15px;
        .rc-textarea {
          border: 0;
          background: transparent;
        }
      }
    }

    .action-btn-container {
      flex-flow: nowrap;
      margin-bottom: var(--spacing-xs);

      .attachment-btn-container {
        margin: 0 var(--spacing-m);

        .attachment-btn {
          height: auto;
          background: none;
          font-size: 0.875rem;
          border-radius: 4px;
          border: none;
        }

        button svg {
          cursor: pointer;
          margin-top: var(--spacing-xxs);
          color: var(--font-gray);
        }
      }
    }
  }
}
