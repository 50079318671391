.details-container {
  display: grid;
  grid-gap: 40px;
  margin-top: 20px;
  .sub-header {
    margin-left: 30px;
  }
  .header {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: var(--primary);
    padding: 25px 30px;
  }

  .profile-details {
    background-color: var(--white);

    .fields-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
      grid-gap: 24px 18px;
      padding: 24px 30px 30px;
      label {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-transform: capitalize;
        color: var(--primary);
        margin-bottom: 14px;
      }

      .full-width {
        grid-column: 1/-1;
      }
      .new-input {
        background: var(--white);
        border: 1px solid rgba(103, 103, 103, 0.3);
        border-radius: 6px;
        padding: 12px 30px;
        &.ant-input-disabled {
          background-color: #ebebeb;
        }
      }
      .ant-select.ant-select-multiple {
        border-radius: 6px;
        .ant-select-selector {
          padding: 0 15px;
          min-height: 52px !important;
        }
      }
      .new-input-prefix {
        .ant-input-group {
          min-height: 52px !important;
          .ant-input-lg {
            background: var(--white);
            border-radius: 6px;
            border: 1px solid rgba(103, 103, 103, 0.3);
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            padding: 12px 30px;
            &.ant-input-disabled {
              background-color: #ebebeb;
            }
          }
        }
      }

      .ant-select.ant-select-single {
        .ant-select-selector {
          min-height: 49px !important;
          border-radius: 6px;
          padding: 0 30px;
          .ant-select-selection-item {
            padding: 5px 0 0 !important;
          }
          .ant-select-selection-placeholder {
            margin-top: 4px;
          }
        }
      }
    }
  }
}
