.signup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow-y: scroll;
  margin: 0;
  .logo-container {
    position: relative;
    top: 50px;
    margin-bottom: 80px;
  }
  .container-step-1 {
    width: auto;
    min-height: 80vh;
    padding: 0 20px;
    .info {
      font-size: 16px;
      line-height: 150%;
    }
    .input-container {
      label {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;

        letter-spacing: -0.01em;
        font-feature-settings: "calt" off;
        margin-bottom: 5px;
      }
      input {
        border: 1px solid #cbd2e0;
        border-radius: 8px;
      }
      input[type="password"] {
        border: 1px solid #cbd2e0;
        border-radius: 8px;
        font-family: Verdana;
      }
    }
    .signup-btn {
      color: var(--white);
      font-weight: 700;
      font-size: 17px;
      line-height: 150%;
      height: 45px;
      letter-spacing: 0.05em;
    }
    .bold {
      font-weight: 600;
      font-size: 15px;
      line-height: 150%;

      text-align: center;
      letter-spacing: -0.01em;
      font-feature-settings: "calt" off;
    }
  }
  .container-step-2 {
    height: auto;
    width: 50vw;

    .info {
      font-weight: 400;
      font-size: 22px;
      line-height: 160%;
      /* or 35px */

      letter-spacing: -0.01em;
      font-feature-settings: "calt" off;
      span {
        font-weight: 600;
      }
    }
    .items-container {
      display: flex;
      flex-wrap: wrap;

      .item-card {
        background-color: #f7f9fc;
        border-radius: 8px;
        margin: 0 15px 15px 0;
        padding: 15px;
        width: 16em;
        height: 15em;
        cursor: pointer;
        transition: all ease 0.4s;

        span {
          font-weight: 600;
          font-size: 16px;
          line-height: 150%;

          letter-spacing: -0.01em;
          font-feature-settings: "calt" off;

          margin-bottom: 20px;
          color: #2d3648;
        }
        p {
          margin-top: 15px;
          white-space: pre-line;
          font-size: 14px;

          color: #2d3648;
        }
        &:hover {
          background-color: #f6d1ff;
        }
        &.active {
          background-color: #f6d1ff;
        }
      }
    }
    .signup-btn {
      color: #fff;
      font-weight: 700;
      font-size: 17px;
      line-height: 150%;
      height: 45px;
      letter-spacing: 0.04em;
    }
  }
  .container-step-3 {
    width: auto;
    padding: 0 20px;
    min-height: 80vh;

    .info-3 {
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: -0.01em;
      font-feature-settings: "calt" off;
    }
    .industry-container {
      p {
        font-size: 22px;
        line-height: 150%;

        letter-spacing: -0.01em;
        font-feature-settings: "calt" off;

        color: #4a5468;
      }
      .ant-select {
        .ant-select-selector {
          border: 1px solid #cbd2e0;
          border-radius: 8px;
        }
      }
      .popular-industries {
        display: flex;
        flex-wrap: wrap;
        .industry {
          width: fit-content;
          height: 38px;
          background: #f7f9fc;
          color: #000000;
          border-radius: 8px;
          margin: 0 20px 15px 0;
          padding: 10px;
          cursor: pointer;
          transition: all ease 0.3s;
          p {
            font-weight: 700;
            font-size: 16px;
            line-height: 140%;
          }
          &:hover {
            background: #f6d1ff;
          }
          &.active {
            background-color: #f6d1ff;
          }
        }
      }
    }
    .signup-btn {
      color: #fff;
      font-weight: 700;
      font-size: 17px;
      line-height: 150%;
      height: 45px;
      letter-spacing: 0.05em;
    }
  }
  .container-step-4 {
    min-height: 80vh;
    padding: 40px 20px 0;
    .info-bold {
      font-weight: 700;
      font-size: 22px;
      line-height: 140%;
    }
    .info {
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;

      span {
        font-weight: 600;
      }
    }
  }
}
.step {
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;

  letter-spacing: -0.01em;
  font-feature-settings: "calt" off;
}
