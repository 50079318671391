.project-item-row-card {
  background-color: var(--white);
  color: var(--gray-9);
  padding: var(--spacing-s);
  border-radius: 0.3rem;
  margin-bottom: var(--spacing-3);
  border: 1px solid var(--color-gray-300);
  cursor: pointer;
  width: fit-content;
  .card-container {
    display: grid;
    grid-template-columns: 90px 250px 130px 200px 190px 185px repeat(5, 140px);
    grid-gap: 10px;
    align-items: center;
    justify-content: space-between;
    width: fit-content;

    .name {
      color: var(--color-gray-800);
      font-weight: 700;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
      max-width: 18.75rem;
      min-width: 2rem;
    }
    .content-container {
      grid-gap: var(--spacing-l);
      gap: var(--spacing-m);
      white-space: nowrap;
      flex-wrap: nowrap;
      text-overflow: ellipsis;
    }

    .project-details-container {
      .project-name-tag {
        background-color: var(--primary);
        color: var(--white);
        border-radius: 3px;

        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: hidden;
      }
    }
  }

  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
}
