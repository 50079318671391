.create-project-modal-container {
  width: 700px !important;

  .ant-modal-header {
    border-bottom: 0 !important;
    padding: var(--spacing-xxl-4) var(--spacing-xxl-2) var(--spacing-xxl-2) !important;
  }

  .ant-modal-body {
    padding: 0 var(--spacing-xxl-2) var(--spacing-xxl-4) !important;
  }

  .search-input-container {
    .search-input {
      border-radius: 5px !important;
      color: var(--gray-17);
      font-size: 0.88rem;
      background-color: var(--white) !important;
      border: 1px solid #d9d9d9 !important;
    }

    .ant-input-affix-wrapper-focused,
    .ant-input-affix-wrapper:focus {
      border-color: var(--primary);
      box-shadow: none;
      border-right-width: 1px;
      outline: 0;
    }
  }

  .content-container {
    height: 65vh;
    overflow-y: auto;
  }

  .template-types-btn-container {
    position: sticky;
    z-index: 1;
    top: 0;
    padding: var(--spacing-xl) 0 var(--spacing-l);
    background-color: #fff;

    .ant-radio-group {
      .ant-radio-button-wrapper {
        border-radius: 50px;
        outline: 0;
        border: 0 !important;
        box-shadow: 0 0 !important;
        padding: 0 var(--spacing-m) !important;
        color: var(--gray-17) !important;
        margin: 0 var(--spacing-s) 0 0 !important;
        cursor: pointer;
        height: 1.75rem;

        line-height: 1.5715;
        position: relative;
        display: inline-block;
        font-weight: 400;
        white-space: nowrap;
        text-align: center;
        touch-action: manipulation;
        font-size: 14px;

        text-transform: capitalize;
      }

      .ant-radio-button-wrapper-checked {
        background: var(--white) !important;
        color: var(--primary) !important;
        border: solid 0.5px var(--primary) !important;
      }

      .ant-radio-button-wrapper:not(:first-child):before {
        background-color: transparent;
      }
    }
  }

  .template-items-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: var(--spacing-s) 0 0 0;
  }
}
