.project-task-list-container {
  background: var(--gray-1);
  border-radius: 6px;

  &:nth-child(1) {
    border-top: 5px solid #583a94;
  }
  &:nth-child(2) {
    border-top: 5px solid #f3af4b;
  }
  &:nth-child(3) {
    border-top: 5px solid #ea605a;
  }
  &:nth-child(4) {
    border-top: 5px solid #00af82;
  }

  .header {
    padding: 16px 18px;
    flex-flow: nowrap;

    .list-name {
      display: block;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      .subheading {
        display: block;
        font-weight: 500;
        margin-top: 4px;
        font-size: 12px;
        line-height: 18px;
        /* identical to box height */

        text-transform: capitalize;

        color: #999999;
      }
    }
  }

  .body {
    height: 80vh;

    .loading-container {
      height: 100%;
    }

    .empty-container {
      height: 100%;
      border: none;
      padding: 0;
    }

    .list-container {
      height: 100%;
      width: 100%;
      overflow-y: auto;
    }
  }
}
