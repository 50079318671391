.project-item-list-container {
  .stats-container {
    background: var(--white);
    padding: 35px;

    border-radius: 6px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 27px;
    .stats {
      padding: 6px 15px 6px 0;

      &:not(:last-child) {
        border-right: 1px solid rgba(42, 42, 42, 0.3);
      }

      span {
        display: block;
        color: var(--primary);
        &.value {
          font-weight: 600;
          font-size: calc(20px + 2vw);
          line-height: 90px;
        }
        &.label {
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .project-item-list-container {
    .stats-container {
      overflow-x: auto;
      padding: 20px;
      grid-gap: 20px;
      .stats {
        &:not(:last-child) {
          padding-right: 25px;
        }
        span {
          &.value {
            font-size: 40px;
            line-height: normal;
          }
          &.label {
            font-size: 1rem;
            line-height: normal;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
