.member-dashboard-container {
  .profile-container {
    .username {
      font-weight: 600;
      font-size: 34px;
      line-height: 51px;
      /* identical to box height */

      display: flex;
      align-items: center;

      color: var(--primary);
    }
    .sub-header {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      /* identical to box height */

      display: flex;
      align-items: center;
      letter-spacing: -0.02em;

      color: var(--primary-light);
      white-space: pre-line;
    }
    .progress-text {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      /* identical to box height */

      display: flex;
      align-items: center;
      text-transform: capitalize;

      color: #999999;
    }
  }
  .stats-container {
    margin-top: 30px;
    background: var(--white);
    padding: 18px 30px;
    border-radius: 6px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 27px;
    .stats {
      padding: 0 10px;
      &:not(:last-child) {
        border-right: 1px solid rgba(42, 42, 42, 0.3);
      }

      span {
        display: block;
        color: var(--primary);
        &.value {
          font-weight: 600;
          font-size: 60px;
          line-height: 90px;
        }
        &.label {
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
        }
      }
    }
  }
}
@media screen and (max-width: 1300px) {
  .member-dashboard-container {
    .stats-container {
      overflow-x: auto;
      padding: 20px;
      grid-gap: 20px;
      .stats {
        &:not(:last-child) {
          padding-right: 25px;
        }
        span {
          &.value {
            font-size: 40px;
            line-height: normal;
          }
          &.label {
            font-size: 1rem;
            line-height: normal;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
