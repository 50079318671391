.project-counter {
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  padding: 5px 9px;
  color: #000000;
  background: #cecece;
  border-radius: 4px;

  &.project-counter-btn {
    margin-right: 5px;
    background: var(--primary-light);
    color: var(--white);
  }
}
