.project-template-item-container {
  display: flex;
  width: 47%;
  padding: var(--spacing-s) var(--spacing-s);
  cursor: pointer;
  border: 1px solid #e9ecef;
  border-radius: 0.5rem;
  margin-bottom: var(--spacing-s);
  transition: all 0.5s;

  .template-image {
    align-self: center;
    width: 6rem;
    margin-right: var(--spacing-xs);
    border-radius: 0.25rem;
  }

  .detail-container {
    font-size: 0.89rem;
    font-weight: 400;
    color: #171a1c;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: 1 1;
    transition: all 0.5s;
  }
}

.selected-template-item,
.project-template-item-container:hover {
  box-shadow: 0 2px 4px #464d5314;
  border: 1px solid var(--primary);

  .detail-container {
    color: var(--primary);
    opacity: 0.9;
  }
}
