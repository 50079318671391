.project-task-kanban-item-container {
  display: grid;
  grid-template-columns: 1fr;
  font-weight: 700;

  .ant-card-body {
    padding: 25px 15px;
  }

  .divider {
    margin: 10px 0;
  }

  .id {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: var(--primary);
    opacity: 0.6;
  }

  .deadline {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: var(--primary-light);
  }
}
