.input-field-container {
  margin-bottom: var(--spacing-xxl-2);

  .label {
    color: var(--gray-80);
    font-weight: 600;
  }

  // .input-container {
  //   .input-area {
  //     color: var(--gray-80);
  //     border: 1px solid var(--gray-40);
  //     border-radius: 0.5rem !important;
  //     font-size: 0.88rem;
  //     padding: 0.7rem var(--spacing-m);
  //     height: 2.5rem;
  //   }

  //   .ant-select-selector {
  //     color: var(--gray-80);
  //     border: 1px solid var(--gray-40) !important;
  //     border-radius: 0.5rem !important;
  //     font-size: 0.88rem;
  //     padding: 0.7rem var(--spacing-m);
  //   }
  // }
}
